exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-challenge-url-js": () => import("./../../../src/pages/{PrismicChallenge.url}.js" /* webpackChunkName: "component---src-pages-prismic-challenge-url-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-prismic-post-url-js": () => import("./../../../src/pages/{PrismicPost.url}.js" /* webpackChunkName: "component---src-pages-prismic-post-url-js" */),
  "component---src-pages-prismic-tool-url-js": () => import("./../../../src/pages/{PrismicTool.url}.js" /* webpackChunkName: "component---src-pages-prismic-tool-url-js" */),
  "component---src-pages-prismic-video-url-js": () => import("./../../../src/pages/{PrismicVideo.url}.js" /* webpackChunkName: "component---src-pages-prismic-video-url-js" */),
  "component---src-pages-tag-prismic-tag-uid-js": () => import("./../../../src/pages/tag/{PrismicTag.uid}.js" /* webpackChunkName: "component---src-pages-tag-prismic-tag-uid-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-challenges-index-js": () => import("./../../../src/templates/challenges-index.js" /* webpackChunkName: "component---src-templates-challenges-index-js" */),
  "component---src-templates-tools-index-js": () => import("./../../../src/templates/tools-index.js" /* webpackChunkName: "component---src-templates-tools-index-js" */),
  "component---src-templates-video-index-js": () => import("./../../../src/templates/video-index.js" /* webpackChunkName: "component---src-templates-video-index-js" */)
}

